import $ from 'jquery'

$(window).ready(function(){
  controlHeaderOpacity()
})

$(window).scroll(function(){
  controlHeaderOpacity()
})

$(window).resize(function(){
  $(window).width() > 800
    ? controlSpNav('deshow')
    : false
})

$('.p-header__button').on('click',function(){
  $(this).hasClass('js-active')
    ? controlSpNav('deshow')
    : controlSpNav('show')
})

const controlHeaderOpacity = () => {
  const $jsHeaderAnimate = document.getElementById('js-header-animate')
  let $win = $(window),
  $scrollTop = $win.scrollTop(),
  $opacity = $scrollTop/200

  if($jsHeaderAnimate !== null){
    if($opacity <= 1){
      $('.p-header').css('background-color',`rgba(255,255,255,${$opacity})`)
      $('.p-header').css('box-shadow',`0px 0px 3px 0px rgba(0, 0, 0, ${$opacity/3})`);
    }else if($opacity > 1){
      $('.p-header').css('background-color',`rgba(255,255,255,.95)`)
      $('.p-header').css('box-shadow',`0px 0px 3px 0px rgba(0, 0, 0, .33)`);
    }
    if($opacity < 0.3 ){
      controlSpNav('deshow')
      $('.p-header').addClass('js-display-none')
    }else{
      $('.p-header').removeClass('js-display-none')
    }
  }else{
    $('.p-header').css('background-color',`rgba(255,255,255,.95)`)
    $('.p-header').css('box-shadow',`0px 0px 3px 0px rgba(0, 0, 0, .33)`);
  }
}

const controlSpNav = (a) =>{
  if(a == 'show'){
    $('.p-header__button').addClass('js-active')
    $('.p-header__content').addClass('js-sp-active')
  }else{
    $('.p-header__button').removeClass('js-active')
    $('.p-header__content').removeClass('js-sp-active')
  }
}

$(function(){
  $('.js-smooth-scroll').click(function() {
     let speed = 300;
     let href= $(this).attr("href");
     let target = $(href == "#" || href == "" ? 'html' : href);
     let headerHeight = $('.p-header').innerHeight()
     let position = target.offset().top - headerHeight;
     $('body,html').animate({scrollTop:position}, speed, 'swing');
     return false;
  });
});
